import React from "react";
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

import logo from '../images/logo.png'

export function Header() {
return (
  <Navbar variant="light" bg="light" expand="lg" className="sticky-top">
    <Container>
      <Navbar.Brand href="/">
        <img alt="logo" src={logo} style={{ width: "40px" }}/>
        <span className="ml-3 align-middle">J<sup>2</sup> Design Services</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarResponsive" />
      <Navbar.Collapse id="navbarResponsive">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link href="/">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/about">About Us</Nav.Link>
          </Nav.Item>
          <NavDropdown title="Services">
            <NavDropdown.Item href="/services">All Services</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Header>Mold Design</NavDropdown.Header>
            <NavDropdown.Item href="/services/injection-mold">Injection Mold</NavDropdown.Item>
            <NavDropdown.Item href="/services/blow-mold">Blow Mold</NavDropdown.Item>
            <NavDropdown.Item href="/services/compression-mold">Compression Mold</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Header>Tool Design</NavDropdown.Header>
            <NavDropdown.Item href="/services/aerospace-tool">Aerospace Tooling</NavDropdown.Item>
          </NavDropdown>
          <Nav.Item>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)
}
