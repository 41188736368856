import React from "react";

export function Footer() {
return (
  <footer className="footer text-faded text-center py-5">
    <div className="container">
      <p className="m-0 small">Copyright © J Squared Design Services 2021</p>
    </div>
  </footer>
)
}
