import React from "react";

import { Helmet } from "react-helmet"

import { Header } from './header';
import { Footer } from './footer';

import logo from '../images/logo.png'

export class Layout extends React.Component {
  render() {
    return (
      <>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, shrink-to-fit=no" />
            <title>{ this.props.title }</title>
            <link rel="icon" type="image/png" href={logo}></link>
          </Helmet>
          <Header />
          { this.props.children }
          <Footer />
        </div>
      </>
    )
  }
}
